/** 
 Copyright Highway9 Networks Inc. 
 */ 
import { COLOR, EDGE_TYPE, OBJ_STATUS, UNKNOWN } from "../../constants";
import { UngroupedRadioID } from "../../constants/types";
import { RadioX } from "../../types/radio";
import { getEdgeStatus, getFrequencyStatus, getRFStatus, getVMCStatus } from "./Graphs/graphHelper";

import { ISite } from "~/types/site";
import { RadioGroup } from "~/types/radioGroup";
import { ColorPaletteGenerator, getNearbyNumberFromObj } from "./colorHelper";

const frequencyCell = {
  cellId: 0,
  band: 0,
  bandwidth: 0,
  power: 0,
  tac: 0,
  earfcn: 0,
  subframeAssignment: 0,
  specialSubframePattern: 0,
  beamwidth: 0,
  gain: 0,
  azimuth: 0,
  downTilt: 0,
  altitude: 0,
};

export function updateRadioDetails(
  radios: RadioX[],
  filter = {
    red: true,
    orange: true,
    green: true,
    warnings: true,
    unconfigured: true,
    thirdParty: true,
    ungrouped: true,
  }
) {
  radios.map((radio) => {
    const aggregationEnabled = radio.carrierAggregationEnabled;
    if (aggregationEnabled && Array.isArray(radio.cellParameters) && radio.cellParameters.length === 1) {
      const secondCell = JSON.parse(JSON.stringify(frequencyCell));
      const _cell = radio.cellParameters[0];
      secondCell.cellId = (_cell.cellId as number) + 1;
      secondCell.pci = (_cell.pci as number) + 1;
      secondCell.band = _cell.band;
      secondCell.bandwidth = _cell.bandwidth;
      secondCell.earfcn = _cell.earfcn;

      radio.cellParameters.push(secondCell);
    }
    return radio;
  });

  const count = {
    green: 0,
    orange: 0,
    red: 0,
    warning: 0,
    unconfigured: 0,
    thirdParty: 0,
    ungrouped: 0,
    total: radios.length,
  };

  const data = [] as RadioX[];
  if (radios.length > 0) {
    radios.forEach((radio) => {
      // flag if push to data
      let pushed = false;
      // update radio group
      if (!radio.radioGroup) {
        radio.radioGroup = {
          id: UngroupedRadioID,
          name: "Ungrouped",
        };
      }

      radio.statusColor = getRadioStatus(radio);
      // radioLog('sss', radio.statusColor);
      // warnings
      if (radio.runtimeInfo?.alarmCount || checkupgradeAvailable(radio)) {
        count.warning += 1;
      }
      if (radio.radioGroup?.id === UngroupedRadioID) {
        // ungrouped
        count.ungrouped += 1;
        if (filter.ungrouped) {
          data.push(radio);
          pushed = true;
        }
      }

      // green
      if (radio.statusColor === COLOR.GREEN) {
        count.green += 1;
        if (filter.green && !pushed) {
          data.push(radio);
        }
      }

      // orange
      else if (radio.statusColor === COLOR.ORANGE) {
        count.orange += 1;
        if (filter.orange && !pushed) {
          data.push(radio);
        }
      }

      // red
      else if (radio.statusColor === COLOR.RED) {
        count.red += 1;
        if (filter.red && !pushed) {
          data.push(radio);
        }
      }

      // unknown
      else if (radio.statusColor === UNKNOWN) {
        count.unconfigured += 1;
        if (filter.unconfigured && !pushed) {
          data.push(radio);
        }
      }

      // third party
      else if (radio.statusColor === COLOR.BLUE) {
        count.thirdParty += 1;
        if (filter.thirdParty && !pushed) {
          data.push(radio);
        }
      }
    });
  }

  return {
    data,
    count,
  };
}

export function getStatus(radio: RadioX) {
  const vmcStatus = radio?.runtimeInfo?.connected ? COLOR.GREEN : COLOR.RED;
  let mmeStatus = COLOR.RED;
  const mmeStatusObject = radio.runtimeInfo?.mmeStatus;
  if (mmeStatusObject) {
    if (mmeStatusObject.length === 1 && mmeStatusObject[0].status) {
      mmeStatus = COLOR.GREEN;
    } else if (mmeStatusObject.length === 1 && !mmeStatusObject[0].status) {
      mmeStatus = COLOR.RED;
    } else if (mmeStatusObject.length === 2 && mmeStatusObject[0].status && mmeStatusObject[1].status) {
      mmeStatus = COLOR.GREEN;
    } else if (
      mmeStatusObject.length === 2 &&
      ((mmeStatusObject[0].status && !mmeStatusObject[1].status) ||
        (!mmeStatusObject[0].status && mmeStatusObject[1].status))
    ) {
      mmeStatus = COLOR.ORANGE;
    } else {
      mmeStatus = COLOR.RED;
    }
  }
  let rf1Status = COLOR.GREEN;
  let rf2Status = COLOR.GREEN;
  const cellNos = radio.cellParameters?.length as number;
  if (cellNos >= 1) {
    if (radio?.cellParameters?.[0]?.rfEnabled === radio?.runtimeInfo?.cellParameters?.[0]?.rfEnabled) {
      if (radio?.cellParameters?.[0]?.rfEnabled) {
        rf1Status = COLOR.GREEN;
        if (
          radio?.sasConfig?.sasEnabled &&
          radio?.cellParameters?.[0]?.earfcn?.[0] !== radio?.runtimeInfo?.cellParameters?.[0]?.earfcn
        ) {
          rf1Status = COLOR.ORANGE;
        }
      } else {
        rf1Status = COLOR.RED;
      }
    } else if (radio?.cellParameters?.[0]?.rfEnabled !== radio?.runtimeInfo?.cellParameters?.[0]?.rfEnabled) {
      rf1Status = COLOR.ORANGE;
    }
  }

  if (radio?.cellParameters?.length === 2) {
    if (radio?.cellParameters?.[1]?.rfEnabled === radio?.runtimeInfo?.cellParameters?.[1]?.rfEnabled) {
      if (radio?.cellParameters?.[1]?.rfEnabled) {
        rf2Status = COLOR.GREEN;
        if (
          radio?.sasConfig?.sasEnabled &&
          radio?.cellParameters?.[1]?.earfcn?.[0] !== radio?.runtimeInfo?.cellParameters?.[1]?.earfcn
        ) {
          rf2Status = COLOR.ORANGE;
        }
      } else {
        rf2Status = COLOR.RED;
      }
    } else if (radio?.cellParameters?.[1]?.rfEnabled !== radio?.runtimeInfo?.cellParameters?.[1]?.rfEnabled) {
      rf2Status = COLOR.ORANGE;
    }
  }

  let status = COLOR.GREEN;
  if (
    radio.isAddedToRadioManager &&
    vmcStatus === COLOR.GREEN &&
    mmeStatus === COLOR.GREEN &&
    rf1Status === COLOR.GREEN &&
    rf2Status === COLOR.GREEN
  ) {
    status = COLOR.GREEN;
  } else if (
    radio.isAddedToRadioManager &&
    vmcStatus === COLOR.GREEN &&
    mmeStatus !== COLOR.RED &&
    (rf1Status !== COLOR.RED || rf2Status !== COLOR.RED)
  ) {
    status = COLOR.ORANGE;
  } else {
    status = COLOR.RED;
  }
  if (!radio.isManaged) {
    status = COLOR.BLUE;
  }
  if (radio.isSimulatedRadio) {
    status = COLOR.UNKNOWN;
  }
  return status;
}

export function getRadioStatus(radio: RadioX) {
  const vmcStatus = getVMCStatus(radio);
  const edgeStatus = getEdgeStatus(radio);
  const rfStatus = getRFStatus(radio);
  const freqStatus = getFrequencyStatus(radio);
  const isEdgeHavingCarrier = radio.edges?.some((item) => { return item.edge.type === EDGE_TYPE.CARRIER_GATEWAY && (!item.carriers) })
  // console.log(radio.name,', vmcStatus : ', vmcStatus, 'edgeStatus :', edgeStatus, 'rfStatus :', rfStatus, 'isRadio Managed :', radio.isAddedToRadioManager ? 'Added' : 'Not Added', ", Freq: ", freqStatus);
  // show green if all are green and if rfStatus is off, show green
  if (radio?.status === OBJ_STATUS.FAILED) {
    return COLOR.RED;
  }
  if (
    vmcStatus === "active" &&
    edgeStatus === "connected" &&
    (rfStatus === "good" || rfStatus === "off") &&
    freqStatus === "GOOD"
  ) {
    return COLOR.GREEN;
  }
  
  if (isEdgeHavingCarrier && radio.serialNumber?.length && radio.runtimeInfo?.overallHealth && radio.edges?.length === 1) {
    return UNKNOWN
  }
  // Simulated Radio case - simulated radio has only edge status - VMC|RF|Freq status is unknown
  if ((radio.isSimulatedRadio || !radio.serialNumber?.length) && edgeStatus == "connected") {
    return COLOR.GREEN;
  } else if ((radio.isSimulatedRadio || !radio.serialNumber?.length) && edgeStatus == "disconnected") {
    return COLOR.RED;
  }


  if (vmcStatus === "unknown" && edgeStatus == "unknown" && rfStatus === "unknown" && freqStatus === "UNKNOWN") {
    return UNKNOWN;
  }
  if (vmcStatus === "thirdParty" && edgeStatus === "thirdParty" && rfStatus === "thirdParty") {
    return COLOR.BLUE;
  }
  if (
    vmcStatus === "active" &&
    edgeStatus === "connected" &&
    (rfStatus === "needAttention" || rfStatus === "off") &&
    freqStatus === "GOOD"
  ) {
    return COLOR.ORANGE;
  }
  if (
    vmcStatus === "active" &&
    edgeStatus === "connected" &&
    (rfStatus === "good" || rfStatus === "off") &&
    freqStatus === "PARTIALLY_GOOD"
  ) {
    return COLOR.ORANGE;
  }
  if (
    vmcStatus === "active" &&
    edgeStatus === "partiallyConnected" &&
    (rfStatus === "good" || rfStatus === "off") &&
    freqStatus === "GOOD"
  ) {
    return COLOR.ORANGE;
  }
  if (
    vmcStatus === "active" &&
    edgeStatus === "partiallyConnected" &&
    (rfStatus === "needAttention" || rfStatus === "off") &&
    freqStatus === "GOOD"
  ) {
    return COLOR.ORANGE;
  }
  if (
    vmcStatus === "active" &&
    edgeStatus === "partiallyConnected" &&
    (rfStatus === "good" || rfStatus === "off") &&
    freqStatus === "PARTIALLY_GOOD"
  ) {
    return COLOR.ORANGE;
  }

  return COLOR.RED;
}

// Uncomment only for logging.
export function radioLog(...args: any[]) { } //new ConsoleLogger(Types.radio).log;

/**
 * Check Radios Status
 */
export function checkStatus(status: string[]) {
  let currentStatus = UNKNOWN;
  if (status.includes(COLOR.GREEN)) {
    currentStatus = COLOR.GREEN;
  }
  // if (status.includes("BLUE")) {
  //     currentStatus = 'BLUE';
  // }
  if (status.includes(COLOR.ORANGE)) {
    currentStatus = COLOR.ORANGE;
  }
  if (status.includes(COLOR.RED)) {
    currentStatus = COLOR.RED;
  }

  return currentStatus;
}

// Source: https://5g-tools.com/4g-lte-earfcn-calculator/
const bandData = {
  "B48": {
    downlinkFrequencyStart: 3550,
    downlinkFrequencyLast: 3700,

    downlinkEarfcnStart: 55240,
    downlinkEarfcnEnd: 56739,

    deltaFGlobal: 0.1,
    duplexMode: "TDD",
  },
  "N48": {
    downlinkFrequencyStart: 3550,
    downlinkFrequencyLast: 3700,
    downlinkEarfcnStart: 636667,
    downlinkEarfcnEnd: 646666,

    deltaFGlobal: 0.015,
    duplexMode: "TDD",
  },
  "N77": {
    downlinkFrequencyStart: 3300,
    downlinkFrequencyLast: 4200,
    downlinkEarfcnStart: 620000,
    downlinkEarfcnEnd: 680000,

    deltaFGlobal: 0.015,
    duplexMode: "TDD",
  },
  "N78": {
    downlinkFrequencyStart: 3300,
    downlinkFrequencyLast: 3800,
    downlinkEarfcnStart: 620000,
    downlinkEarfcnEnd: 653332,

    deltaFGlobal: 0.015,
    duplexMode: "TDD",
  }
};

function getBandData(band: string) {
  return bandData[band as keyof typeof bandData];
}

function roundToEven(f: number) {
  const half = f / 2;
  let halfRound = Math.round(half);
  if (Math.abs(halfRound - half) === 0.5) {
      // If the value is X.5, round to the smaller value
      halfRound = half - 0.5;
  }
  return halfRound * 2;
}

export function getFrequencyFromEarfcn(earfcn: number, band = "B48") {
  const bandData = getBandData(band);
  const frequency = Math.round((earfcn - bandData.downlinkEarfcnStart) * bandData.deltaFGlobal + bandData.downlinkFrequencyStart);
  return frequency;
}

export function getEarfcnFromFrequency(frequency: number, band = "B48") {
  const bandData = getBandData(band);
  const earfcn = roundToEven((frequency - bandData.downlinkFrequencyStart) / bandData.deltaFGlobal + bandData.downlinkEarfcnStart);
  return earfcn;
}

export function getBandChannels(bandwidth: number, band = "B48") {
  if (bandwidth < 5) {
    console.warn("Bandwidth is too low");
    return [];
  }
  const bandData = getBandData(band ?? "B48");
  if (!bandData) {
    console.warn("Band data not found for band", band);
    return [];
  }
  const channels = Math.floor((bandData.downlinkFrequencyLast - bandData.downlinkFrequencyStart) / bandwidth);

  const technology = band[0] === "N" ? "5G" : "4G";
  // const delta = 300 / channels;

  const generateColors = new ColorPaletteGenerator(bandwidth, technology).createColorPalette();
  const colorPallete = generateColors[band];

  // console.log("Color Pallete", colorPallete, generateColors);

  const channelList: { channel: number; frequency: number; earfcn: number; color: string }[] = [];
  for (let i = 0, chNumber = 1; i < channels; i++) {
    const halfBandwidth = bandwidth / 2;
    const centerFrequency = bandData.downlinkFrequencyStart + halfBandwidth + bandwidth * i;
    if (((centerFrequency - halfBandwidth) < 3550) || ((centerFrequency + halfBandwidth) > 3700)) {
      continue;
    }
    const earfcn = getEarfcnFromFrequency(centerFrequency, band);
    // console.table( { earfcn, freqStart : bandData.downlinkFrequencyStart , freqEnd : bandData.downlinkFrequencyLast, centerFrequency, bandwidth, delta});
    // const colorPercent = normalizeDataPoint(centerFrequency, bandData.downlinkFrequencyStart, bandData.downlinkFrequencyLast, 20, 320);
    // const saturationPercent = normalizeDataPoint(i % delta, 0, delta - 1, 40, 70);
    // const lightPercent = 50;
    // // const lightPercent = normalizeDataPoint(i % 3, 2, 0, 30, 60);
    // const color = `hsl(${colorPercent}, ${saturationPercent}%, ${lightPercent}%)`;
    const freq = getNearbyNumberFromObj(centerFrequency, colorPallete);

    const color = colorPallete?.[freq] ?? "#000"

    // console.log("Freq", freq, color);

    const channel = {
      channel: chNumber,
      frequency: centerFrequency,
      earfcn: earfcn,
      color: color
    };
    // console.log("Channel", i + 1, ":", centerFrequency, "MHz", "Earfcn:", earfcn, i % 3, channel.color);
    channelList.push(channel);
    chNumber += 1;
  }

  return channelList;
}

export function checkupgradeAvailable(data: RadioX) {
  if (data.availableUpgrades) {
    return data.availableUpgrades?.some(item => item.recommended)
  }
  return false
}

export type RadioChannels = ReturnType<typeof getBandChannels>;

export function ExtractingRadioGroupandSitesDataFromFilteredData(data: any, edgeData: any[], edgePoolData: any[], mobilityData: any[], siteData: any[], radioGroupData: any[]) {

  const IRadioGroups: RadioGroup[] = data?.map((data: any, index: number) => {
    const locationString = `${data["E-911 Street Address (Dispatchable Location)"] ? data["E-911 Street Address (Dispatchable Location)"] + "," : ""}${data["E-911 City"] ? data["E-911 City"] + "," : ""}${data["E-911 State"] ? data["E-911 State"] + "," : ""} ${data["E-911 Zip Code"] ? data["E-911 Zip Code"] + "," : ""}USA`;
    const MobilityProfileName = data["Mobility Profile"]
    const MobilityProfileId = mobilityData.find((item) => item.name === MobilityProfileName)?.id;

    const _radioGroup = radioGroupData.find((item) => item.name === data["Radio Group"])
    const RadioGroupLocation = _radioGroup?.plan.calibrationPoints.map.center.name
    const RadioGroupLatitude = _radioGroup?.plan.calibrationPoints.map.center.latitude
    const RadioGroupLongitude = _radioGroup?.plan.calibrationPoints.map.center.longitude
    const RadioGroupStatus = _radioGroup?.status


    return {
      id: data.RadioGroupID?.trim() || `new-${index}-${Date.now()}`,
      name: data["Radio Group"],
      radioSite: {
        id: data.RadioSiteID?.trim() || `new-${index}-${Date.now()}`,
        name: data["Building (Site)"],
      },
      zoneType: data["Antenna Environment (ZoneType)"] !== undefined ? data["Antenna Environment (ZoneType)"] === "In-Building" ? "INDOOR" : "OUTDOOR" : "",
      edges: [1, 2]?.map((i: any) => {
        const edgeKey = `Edge${[i]}`;
        const name = data[edgeKey]?.Name;

        const _edge = edgeData.find((item) => item.name === name)
        const edgeId = _edge?.id;
        const edgeType = _edge?.services[0].name;
        const isEdgePoolExisted = edgePoolData.some((item) => item.edgePoolname === name)
        const edgePoolId = edgePoolData?.find((item) => item.edgePoolname === name)?.edgePoolId
        const edgePoolServices = edgePoolData?.find((item) => item.edgePoolname === name)?.services[0].name;


        const edgeObject = edgeType === "CARRIER_GATEWAY" && data[edgeKey]?.Carriers !== undefined ? {
          carriers: [
            {
              id: `mnoOperator-${index}-${Date.now()}`,
              name: data[edgeKey]?.Carriers
            }
          ],
          edge: {
            id: edgeId,
            name: name,
            type: edgeType,
          },
          ipsecMode: data[edgeKey]?.IPSecMode,
        } : {
          edge: {
            id: isEdgePoolExisted ? edgePoolId : edgeId,
            name: name,
            type: isEdgePoolExisted ? edgePoolServices : edgeType,
          },
          ipsecMode: data[edgeKey]?.IPSecMode,
        }


        if (i === 2 && !name) {
          return null; // Skip the second object if index is 2 and there is no name
        }
        return edgeObject

      }).filter(Boolean) || [],
      sas: {
        sasEnabled: data["SAS Enable"],
        sasUserId: data["SAS UserId"],
        allowOnlySelectedFrequency: false,
        frequencySelectionLogic: data["Frequency Selection Logic"],
      },
      datapath: {
        localExit: {
          enabled: false,
          mode: "NAT",
          ipPool: "10.0.0.0",
          enableStaticIp: false,
          staticIpStart: "",
          staticIpEnd: "",
          ipPoolNetmask: "255.255.255.0",
        },
      },
      frequency: {
        band: "B48",
        bandwidth: data.Cell1.Bandwidth,
        specialSubframePattern: data["Special Subframe Pattern"],
        subframeAssignment: data["Subframe Assignment"],
      },
      mobilityProfile: {
        id: MobilityProfileId,
        name: MobilityProfileName,
      },
      floor: data["Floor No"] ?? 1,
      status: data.ID ? RadioGroupStatus : "NOT_DEPLOYED",
      plan: {
        calibrationPoints: {
          map: {
            center: {
              latitude: data.RadioGroupID ? RadioGroupLatitude : data["E-911 Latitude (NAD83)"],
              longitude: data.RadioGroupID ? RadioGroupLongitude : data["E-911 Longitude (NAD83)"],
              name: data.RadioGroupID ? RadioGroupLocation : locationString,
            },
            points: data?.plan?.calibrationPoints?.map?.points || [],
            view: data?.plan?.calibrationPoints?.map?.view || "",
            zoom: data?.plan?.calibrationPoints?.map?.zoom || 0,
          },
        },
      },
    };
  });

  const ISites: ISite[] = data?.map((data: any, index: number) => {
    const locationString = `${data["E-911 Street Address (Dispatchable Location)"] ? data["E-911 Street Address (Dispatchable Location)"] + "," : ""}${data["E-911 City"] ? data["E-911 City"] + "," : ""}${data["E-911 State"] ? data["E-911 State"] + "," : ""} ${data["E-911 Zip Code"] ? data["E-911 Zip Code"] + "," : ""}USA`;
    const RadioSiteLocation = siteData.find((item) => item.name === data["Building (Site)"])?.location.name
    const RadioSiteLatitude = siteData.find((item) => item.name === data["Building (Site)"])?.location.latitude
    const RadioSiteLongitude = siteData.find((item) => item.name === data["Building (Site)"])?.location.longitude
    const RadioSiteStatus = siteData.find((item) => item.name === data["Building (Site)"])?.status


    return {
      location: {
        latitude: data.RadioSiteID ? RadioSiteLatitude : data["E-911 Latitude (NAD83)"] || "",
        longitude: data.RadioSiteID ? RadioSiteLongitude : data["E-911 Longitude (NAD83)"] || "",
        name: data.RadioSiteID ? RadioSiteLocation : locationString,
        zoom: 12,
      },
      status: data.RadioSiteID ? RadioSiteStatus : "NOT_DEPLOYED",
      id: data.RadioSiteID || ``,
      name: data["Building (Site)"] || "",
    };
  });
  return {
    IRadioGroups,
    ISites,
  };
}
export type RadioChannel = {
  channel: number;
  frequency: number;
  earfcn: number;
  color: string;
}
