/** 
 Copyright Highway9 Networks Inc. 
 */ 
import { authenticationService } from "./authentication-service";

export const networkService = {
  getNetworks,
  createNetwork,
  updateNetwork,
  getSNMPData,
  AddAndUpdateSNMPData,
  deleteSNMP,
  getAAAServerProfilesData,
  AddAndUpdateAAAServerProfilesData,
  deleteAAAServerProdile,
};

function getNetworks() {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
      Authorization: "Bearer " + authenticationService.currentUserValue.token,
    },
  };

  return fetch(`/api/v1/orch/networks`, requestOptions);
}
function getSNMPData() {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
      Authorization: "Bearer " + authenticationService.currentUserValue.token,
    },
  };

  return fetch(`/api/v1/orch/networks/monitoringSystems`, requestOptions);
}
function getAAAServerProfilesData() {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
      Authorization: "Bearer " + authenticationService.currentUserValue.token,
    },
  };

  return fetch(`/api/v1/orch/networks/aaaServerProfile`, requestOptions);
}

function createNetwork(name, description, type) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
      Authorization: "Bearer " + authenticationService.currentUserValue.token,
    },
    body: JSON.stringify({
      name: name,
      description: description,
      type: type,
    }),
  };

  console.log("Creating network  with name " + name);
  return fetch(`/api/v1/orch/network`, requestOptions);
}
function updateNetwork(network, id) {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
      Authorization: "Bearer " + authenticationService.currentUserValue.token,
    },
    body: JSON.stringify(network),
  };

  console.log("Updating network with name " + network.name);
  return fetch(`/api/v1/orch/networks/${network.id}`, requestOptions);
}
function AddAndUpdateSNMPData(reqBody, id = null) {
  let snmpID = "";
  if (id) snmpID = `/${id}`;
  const requestOptions = {
    method: id ? "PUT" : "POST",
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
      Authorization: "Bearer " + authenticationService.currentUserValue.token,
    },
    body: JSON.stringify(reqBody),
  };

  return fetch(`/api/v1/orch/networks/monitoringSystems${snmpID}`, requestOptions);
}
function AddAndUpdateAAAServerProfilesData(reqBody, id = null) {
  let snmpID = "";
  if (id) snmpID = `/${id}`;
  const requestOptions = {
    method: id ? "PUT" : "POST",
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
      Authorization: "Bearer " + authenticationService.currentUserValue.token,
    },
    body: JSON.stringify(reqBody),
  };

  return fetch(`/api/v1/orch/networks/aaaServerProfile${snmpID}`, requestOptions);
}

function deleteSNMP(id) {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
      Authorization: "Bearer " + authenticationService.currentUserValue.token,
    },
  };

  console.log("Deleting snmp : " + id);
  return fetch(`/api/v1/orch/networks/monitoringSystems/${id}`, requestOptions);
}
function deleteAAAServerProdile(id) {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
      Authorization: "Bearer " + authenticationService.currentUserValue.token,
    },
  };

  console.log("Deleting aaaServerProfiles : " + id);
  return fetch(`/api/v1/orch/networks/aaaServerProfile/${id}`, requestOptions);
}
