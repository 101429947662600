/**
 * Copyright Highway9 Networks Inc.
 */

import React, { useEffect, useState } from "react";
import { text } from "../../components/TextResources";
import { Alert, Checkbox, FormControlLabel, Grid, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "~/store/hooks";
import { utilityActions } from "~/store/context/utilitySlice";
import timezones from "~/assets/timezones.json";
import { SearchSelector } from "@h9/ui-lib";
import moment from "moment";
import { authenticationService } from "~/services";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { settingsAirgapEnabled } from "~/store/context/settingsSlice";


export function GeneralDetails(props) {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (props.user?.timeZone)
      dispatch(utilityActions.setTimeZone(props.user?.timeZone));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.user]);

  const AirGapEnabled = useAppSelector(settingsAirgapEnabled);


  // function getConsoleURL() {
  //   const url = window.location.hostname;

  //   const subdomain = url.split(".");

  //   if (subdomain[0] === "localhost") {
  //     return "http://console.localhost:3000";
  //   }
  //   // remove the first part of the subdomain and join the rest to get the console url with console. prefix
  //   subdomain.shift();
  //   return "https://console." + subdomain.join(".");
  // }

  const { name, email, authType, } = props.user;
  const [changePassword, setChangePassword] = useState(false);

  const [errors, setErrors] = useState('');

  function onChange(e) {
    const type = e.target.name;
    const value = e.target.value;

    props.setUser((prev) => {
      return {
        ...prev,
        [type]: value
      }
    })
  }

  const handleChangePassword = (event) => {
    setChangePassword(event.target.checked);
  };

  useEffect(() => {
    if (!props.user?.oldPassword || !props.user?.newPassword || !props.user?.repeatPassword) {
      setErrors("");
    } else if (props.user?.newPassword !== props.user?.repeatPassword) {
      setErrors("Passwords do not match");
    } else {
      setErrors("");
    }
  }, [props.user?.newPassword, props.user?.repeatPassword, props.user?.oldPassword]);

  const isTenant = authenticationService.isTenant();

  const [showPassword, setShowPassword] = useState(true);

  return (
    <Grid container>
      <div className='flex' style={{ marginTop: 16, width: '80%' }}>
        <Grid item gap={2} display={"flex"} flexDirection={"column"} >
          <Typography component={'h6'} variant="caption">User Name</Typography>
          <Typography component={'h6'} variant="caption">Email </Typography>
          <Typography component={'h6'} variant="caption">Auth Type</Typography>
        </Grid>
        <div>
          <Typography component={'h6'} variant="subtitle1">{name}</Typography>
          <Typography component={'h6'} variant="subtitle1">{email}</Typography>
          <Typography component={'h6'} variant="subtitle1">{authType?.join(", ")}</Typography>
        </div>
      </div>

      <Grid item xs={12}>
        {/* <TextField
          variant="standard"
          disabled
          label={text.userName}
          value={name}
          id="name"
          fullWidth
          required
        ></TextField>
        <br />
        <br />
        <TextField
          variant="standard"
          disabled
          label={text.email}
          value={email}
          id="email"
          fullWidth
          required
        ></TextField>
        <br />
        <br />
        <TextField
          variant="standard"
          disabled
          label={"Auth Type"}
          value={authType?.join(", ")}
          id="auth type"
          fullWidth
          required
        ></TextField>
        <br />
        <br /> */}

        <TimeZone timezone={props.timezone} onChange={props.changeTimezone} />

        {authType?.includes("PASSWORD") && (isTenant || AirGapEnabled) &&
          <>
            <FormControlLabel
              control={
                <Checkbox
                  checked={changePassword}
                  onChange={handleChangePassword}
                  name="changePassword"
                  color="primary"
                />
              }
              label="Change Password"
            />
          </>
        }

        {changePassword &&
          <>
            {errors && <Alert severity="error" style={{ marginBottom: 10, width: '100%' }}>
              {errors}
            </Alert>}
            <TextField variant="standard" label={text.newPassword} value={props.user.newPassword} name="newPassword" type={showPassword ? "text" : "password"} onChange={onChange} fullWidth required
              InputProps={{
                endAdornment: <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    onMouseDown={(e) => e.preventDefault()}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }}
            ></TextField>

            <TextField variant="standard" label={text.repeatPassword} value={props.user.repeatPassword} name="repeatPassword" type={showPassword ? "text" : "password"} onChange={onChange} fullWidth required></TextField>
          </>
        }
      </Grid>
    </Grid>
  );
}


function TimeZone(props) {
  const timeZones = React.useMemo(() => {
    return Object.entries(timezones).map(([tz, name]) => {
      return { label: name, value: tz, group: "All" };
    });
  }, []);

  const systemTimeZone = React.useMemo(() => moment.tz.guess(), []);

  const current = React.useMemo(() => {
    return { ...timeZones.find((zone) => zone.value === systemTimeZone), group: "System", value: null }
  }, [timeZones, systemTimeZone]);

  return (
    <>
      <label style={{ fontSize: 10 }}>Timezone</label>
      <SearchSelector
        value={timeZones.find((zone) => zone.value === (props.timezone || systemTimeZone))}
        options={[current, ...timeZones]}
        onChange={(zone) => props.onChange(zone?.value)}
        style={{ background: 'transparent', color: 'black', border: '1px solid #ccc' }}
      />
    </>

  )

}