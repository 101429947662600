import { jsx as t, jsxs as e } from "react/jsx-runtime";
import { Button as f } from "@mui/material";
import { AppBar as u } from "../../atoms/AppBar/AppBar.js";
import { Avatar as y } from "../../atoms/Avatar/Avatar.js";
import "react";
import "../../../Chatbot.style-27577ad5.js";
import "../../../style.module-3bbdaa05.js";
import "../../../DateTimeSelectorMenu-62cb07f8.js";
import "moment-timezone";
import "../../../runtime-6393d96e.js";
import "../../../TableContext-27c26002.js";
import "../../ReactTable/SearchFilter.js";
import "../../ReactTable/TablePaginationActions.js";
import "../../../styles/theme.js";
import "highcharts-react-official";
import "highcharts";
import "../../molecules/Widgets/WidgetTimelineGraph/WidgetTimelineGraph.styles.js";
import "../../../server.browser-ec1ee1ca.js";
import "../../../transform-17824b7f.js";
import "../../molecules/NetworkMap/NetworkMapUtils.js";
import F from "../../../assets/icons/UserIcon.js";
import "../../../ChatbotContext-74017a6b.js";
import "../../atoms/FormControl/FormControl.js";
import "../../atoms/FormControlLabel/FormControlLabel.js";
import "../../../map-85279df3.js";
import { Grid as o } from "../../atoms/Grid/Grid.js";
import "../../atoms/Paper/Paper.js";
import "../../atoms/Box/Box.js";
import "../../atoms/Radio/Radio.js";
import "../../atoms/RadioGroup/RadioGroup.js";
import { Typography as i } from "../../atoms/Typography/Typography.js";
import { d as b } from "../../../KeyboardArrowDown-11e4031f.js";
import { Toolbar as C } from "../../atoms/Toolbar/Toolbar.js";
import "../../atoms/Stepper/Stepper.js";
import "../../atoms/Step/Step.js";
import "../../atoms/StepLabel/StepLabel.js";
function nt({
  children: n,
  subtitle: p,
  title: a,
  userName: m,
  role: l,
  profileCard: d,
  setAnchorEl: c = () => null,
  type: s = "primary",
  style: x
}) {
  const g = (h) => {
    c(h.currentTarget);
  };
  return /* @__PURE__ */ t(
    u,
    {
      position: "static",
      sx: [r.appBar, v[s].appBar, x || {}],
      children: /* @__PURE__ */ e(C, { sx: r.toolBar, children: [
        /* @__PURE__ */ e(o, { container: !0, sx: r.leftContainer, children: [
          /* @__PURE__ */ t(o, { sx: r.headings, children: /* @__PURE__ */ t(o, { sx: r.container, children: /* @__PURE__ */ e(
            o,
            {
              container: !0,
              item: !0,
              alignItems: "center",
              sx: r.titleContainer,
              children: [
                /* @__PURE__ */ t(i, { type: "heading1", sx: r.title, children: a }),
                /* @__PURE__ */ t(
                  i,
                  {
                    type: "heading2",
                    color: "#747982",
                    children: p
                  }
                )
              ]
            }
          ) }) }),
          n
        ] }),
        /* @__PURE__ */ e(
          f,
          {
            variant: "text",
            sx: r.user,
            endIcon: /* @__PURE__ */ t(b, { color: "disabled", style: { fontSize: "16px" } }),
            onClick: g,
            children: [
              /* @__PURE__ */ t(
                y,
                {
                  width: 24,
                  height: 24,
                  children: /* @__PURE__ */ t(F, { size: 40, color: "#878787" })
                }
              ),
              /* @__PURE__ */ e(o, { container: !0, flexDirection: "column", alignItems: "start", children: [
                /* @__PURE__ */ t(
                  i,
                  {
                    type: "bodyText2",
                    sx: r.avatarText,
                    children: m
                  }
                ),
                /* @__PURE__ */ t(
                  i,
                  {
                    type: "bodyText3",
                    sx: r.avatarText,
                    children: l
                  }
                )
              ] })
            ]
          }
        ),
        d
      ] })
    }
  );
}
const r = {
  appBar: () => ({
    zIndex: 1201,
    width: "100%",
    justifyContent: "center",
    paddingLeft: 0,
    borderRadius: "16px 0 0 0",
    height: "56px"
  }),
  container: () => ({
    gap: "8px",
    paddingLeft: "16px"
  }),
  leftContainer: {
    gap: "8px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  logo: () => ({
    fontSize: "1.5rem",
    padding: "10px 14px",
    display: "flex",
    alignItems: "center"
  }),
  toolBar: {
    minHeight: "0 !important",
    height: "100%",
    gap: {
      xs: "8px",
      sm: "16px"
    },
    padding: "0 !important"
  },
  titleContainer: {
    alignItems: "center",
    gap: "8px"
  },
  title: {
    fontSize: "16px",
    fontWeight: "600",
    color: "#226A58"
  },
  user: {
    whiteSpace: "nowrap",
    display: "flex",
    alignItems: "center",
    gap: "8px",
    background: "#FFFFFF",
    height: "100%",
    padding: "0 24px",
    textTransform: "unset",
    borderRadius: 0,
    borderLeft: "1px solid #EFEFEF",
    ".MuiAvatar-root": {
      backgroundColor: "#FFF"
    },
    ":hover, :hover .MuiAvatar-root": {
      backgroundColor: "#EEE"
    }
  },
  headings: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  avatarText: {
    color: "#878787",
    letterSpacing: "0.23px"
  }
}, v = {
  primary: {
    appBar: {
      backgroundColor: "#FFFFFF",
      boxShadow: "none"
    }
  },
  secondary: {
    appBar: {}
  }
};
export {
  nt as TopBar
};
