/**
 * Copyright Highway9 Networks Inc.
 */
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "..";
import { Types } from "../../constants/types";
import { subscriberService } from "../../services";
import { DeviceGroup } from "../../types/deviceGroup";
import { Device } from "~/types/device";

type initState = {
  data: DeviceGroup[];
  current: DeviceGroup | null;
  open: boolean;
  selectedDeviceGroupIDs: string[];
  selectedDevices: Device[]
};

export const defaultDeviceGroup: DeviceGroup = {
  name: "",
  activePolicies: [],
  ueAmbr: {
    uplink: 0,
    downlink: 0,
  },
};

const initialState: initState = {
  data: [],
  current: null,
  open: false,
  selectedDeviceGroupIDs: [Types.all],
  selectedDevices: [],
};

export const fetchDeviceGroups = createAsyncThunk("deviceGroup/fetchDeviceGroups", async () => {
  try {
    const deviceGroups = await subscriberService.getDeviceGroups();
    return deviceGroups;
  } catch (error) {
    console.log(error);
    throw error;
  }
});

const deviceGroupSlice = createSlice({
  name: "deviceGroups",
  initialState,
  reducers: {
    setValues: (state, action: PayloadAction<DeviceGroup | null>) => {
      state.current = action.payload;
    },
    setDeviceGroupOpen: (state, action: PayloadAction<boolean>) => {
      state.open = action.payload;
    },
    setSelectedDeviceGroupIDs: (state, action: PayloadAction<string[]>) => {
      state.selectedDeviceGroupIDs = action.payload;
    },
    setSelectedDevices: (state, action: PayloadAction<Device[]>) => {
      state.selectedDevices = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDeviceGroups.fulfilled, (state, action) => {
      state.data = action.payload;
    });
  },
});

export const deviceGroupActions = deviceGroupSlice.actions;
export default deviceGroupSlice.reducer;

export const subscriberDeviceGroups = (state: RootState) => state.deviceGroup.data;
export const deviceGroupState = (state: RootState) => state.deviceGroup.current;
export const deviceGroupOpen = (state: RootState) => state.deviceGroup.open;
export const deviceGroupSelectedIDs = (state: RootState) => state.deviceGroup.selectedDeviceGroupIDs;
export const deviceGroupSelectedDevices = (state: RootState) => state.deviceGroup.selectedDevices;