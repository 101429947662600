var d = Object.defineProperty;
var f = (r, n, i) => n in r ? d(r, n, { enumerable: !0, configurable: !0, writable: !0, value: i }) : r[n] = i;
var u = (r, n, i) => (f(r, typeof n != "symbol" ? n + "" : n, i), i);
import { useState as g, useEffect as w } from "react";
const c = window.SpeechRecognition || window.webkitSpeechRecognition;
class e {
  constructor(n = "en-US") {
    u(this, "recognition");
    u(this, "listening", !1);
    if (!c)
      throw new Error("Speech recognition is not supported in this browser");
    this.recognition = new c(), this.recognition.lang = n;
  }
  static isSupported() {
    return !!c;
  }
  start() {
    if (!e.isSupported()) {
      console.error("Speech recognition is not supported in this browser");
      return;
    }
    this.listening = !0, this.recognition.start();
  }
  onStart(n) {
    this.listening = !0, this.recognition.onaudiostart = n;
  }
  onEnd(n) {
    this.listening = !1, this.recognition.onaudioend = n;
  }
  onError(n) {
    this.recognition.onerror = (i) => {
      n(i.error);
    };
  }
  stop() {
    this.recognition.stop(), this.listening = !1;
  }
  onResult(n) {
    this.recognition.onresult = (i) => {
      console.log(i.results);
      const o = i.results[i.results.length - 1][0].transcript;
      this.listening = !1, n(o);
    };
  }
}
const t = e.isSupported() ? new e() : null, R = () => {
  const [r, n] = g(null), [i, o] = g(t == null ? void 0 : t.listening), [a, s] = g(null);
  w(() => {
    if (!e.isSupported()) {
      s("Speech recognition is not supported in this browser");
      return;
    }
    t == null || t.onStart(() => {
      s(null), n(null), o(!0);
    }), t == null || t.onResult((l) => {
      n(l), s(null);
    }), t == null || t.onError((l) => {
      s(l), n(null), o(!1);
    }), t == null || t.onEnd(() => {
      s(null), n(null), o(!1);
    });
  }, []);
  function p() {
    t == null || t.start();
  }
  function S() {
    t == null || t.stop();
  }
  return { transcript: r, error: a, startListening: p, stopListening: S, isListening: i };
};
export {
  R as useSpeechToText
};
