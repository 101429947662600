/** 
 Copyright Highway9 Networks Inc. 
 */ 
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";
import { Types } from "../../constants/types";
import { Hardware } from "~/types/Hardware";
import { hardwareInventoryService } from "~/services";

type initState = {
    open:boolean;
    export: {
        open: boolean;
        id: string | null;
        isRunning: boolean;
    };
    loading: boolean;
    current: Hardware | null;
    data: Array<Hardware>;

}

const initialState: initState = {
    export: {
        open: false,
        id: null,
        isRunning: false
    },
    loading: false,
    current: null,
    data: [],
    open: false,
}

export const fetchHardware = createAsyncThunk("vmcHardware/fetchHardware", async () => {
    const data = await hardwareInventoryService.getHardwareInventory({
        status : "",
    });

    return data;
});

const hardwareSlice = createSlice({
    name: Types.hardware,
    initialState,
    reducers: {
        setCIQExportOpen: (state, action: PayloadAction<boolean>) => {
            state.export.open = action.payload;
        },
        setCIQExportId: (state, action: PayloadAction<string | null>) => {
            state.export.id = action.payload;
        },
        setCIQExportIsRunning: (state, action: PayloadAction<boolean>) => {
            state.export.isRunning = action.payload;
        },
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setCurrent: (state, action: PayloadAction<Hardware | null>) => {
            state.current = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchHardware.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchHardware.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
        });
        builder.addCase(fetchHardware.rejected, (state) => {
            state.loading = false;
        });
    }
})
export const hardwareActions = hardwareSlice.actions;
export default hardwareSlice.reducer;
export const HardwareState = (state: RootState) => state.hardware.current
export const HardwareData = (state: RootState)  => state.hardware.data
export const HardwareOpen = (state: RootState)  => state.hardware.open

export const HardwareFromSerialNumber = (serial : string) => (state: RootState) => state.hardware.data.find(hardware => hardware.hardwareId === serial);


export const hardwareExportId = (state: RootState) => state.hardware.export.id;
export const hardwareExportIsRunning = (state: RootState) => state.hardware.export.isRunning;
export const hardwareExportOpen = (state: RootState) => state.hardware.export.open;

export const hardwareLoading = (state: RootState) => state.hardware.loading;