/** 
 Copyright Highway9 Networks Inc. 
 */ 
import { MetricGraph } from "~/types/metricGraph";
import APIService from "./APIServices";

const radiosMetricsTemplate = new APIService<MetricGraph>("radios/metrics/templates");

export const radioMetricsTemplateService = {
    getTemplates : radiosMetricsTemplate.getAll,
    createTemplate :  radiosMetricsTemplate.create,
    updateTemplate : radiosMetricsTemplate.update,
    deleteTemplate :  radiosMetricsTemplate.delete,
  }
