/** 
 Copyright Highway9 Networks Inc. 
 */ 
import { PayloadAction, createAsyncThunk, createSlice, nanoid } from "@reduxjs/toolkit";
import { radioMetricsTemplateService } from "~/services";
import { CategoryGraph, MetricGraph, defaultMetrics, defaultGraphs, defaultTemplate, defaultCategory, AggregationType } from "~/types/metricGraph";
import { RootState } from "..";
import { HIGHWAY9 } from "~/constants";

type initState = {
    open: boolean,
    data: MetricGraph[],
    updatedTemplate: MetricGraph[];
    currentEditable: MetricGraph | null,
    templateLoading: boolean,

    vendor: string,
    technology: string,

    testGraphOptions: {
        graph: Omit<CategoryGraph, "graphOptions">,
        sourceObj: string
    }
}

const initialState: initState = {
    open: false,
    data: [],
    updatedTemplate: [],
    currentEditable: null,
    templateLoading: false,
    technology: '4G',

    vendor: HIGHWAY9,

    // testing graph
    testGraphOptions: {
        graph: defaultGraphs,
        sourceObj: 'radio'
    }
}

export const fetchMetricsTemplate = createAsyncThunk(`kpiTemplate/fetchMetricsTemplate`, radioMetricsTemplateService.getTemplates);

export const kpiTemplateSlice = createSlice({
    name: "kpiTemplate",
    initialState,
    reducers: {
        setOpen(state, action: PayloadAction<boolean>) {
            state.open = action.payload
        },
        setTemplateLoading(state, action: PayloadAction<boolean>) {
            state.templateLoading = action.payload
        },
        setCurrentEditableTemplate(state, action: PayloadAction<MetricGraph | null>) {
            state.currentEditable = action.payload as any
        },
        setTechnology(state, action: PayloadAction<string>) {
            state.technology = action.payload
        },

        // editing template

        setVendor(state, action: PayloadAction<string>) {
            state.vendor = action.payload
        },

        setTestGraphOptions(state, action: PayloadAction<{
            graph: CategoryGraph,
            sourceObj: string
        }>) {
            state.testGraphOptions = {
                graph: action.payload.graph as Omit<CategoryGraph, "graphOptions">,
                sourceObj: action.payload.sourceObj
            }
        },
        deleteTemplate(state, action: PayloadAction<string>) {
            const templateId = action.payload;
            state.data = state.data.filter(d => d.id !== templateId);
        },

        addTemplate(state) {
            state.data.push({
                ...defaultTemplate as any,
                vendorType: ToCamelCase(state.vendor),
                technology: state.technology,
                isDraft: true,
                isSystem: false,
                name: `New Template ${state.data.length + 1}`,
                id: `metricsTemplate-new-${nanoid(16)}`
            })
        },

        cloneTemplate(state, action: PayloadAction<string>) {
            const templateId = action.payload;
            const template = state.data.find(d => d.id === templateId);

            if (template) {
                const clone = JSON.parse(JSON.stringify(template));
                clone.id = `metricsTemplate-clone-${nanoid(16)}`;
                clone.name = `${clone.name}_1`;
                clone.isDraft = true;
                clone.isSystem = false;
                state.data.push(clone);

                // set it as current editable
                state.currentEditable = clone;
            }
        },

        updateTemplate(state) {

            if (state.currentEditable) {
                const template = JSON.parse(JSON.stringify(state.currentEditable));
                const index = state.data.findIndex(d => d.id === template.id);

                // if it is a new template and not a system template then set it as draft.
                template.isDraft = template.isSystem ? template.isDefault !== state.data[index].isDefault : true;
                state.data[index] = template;
            }
        },

        // editing template

        setTemplateName(state, action: PayloadAction<string>) {
            if (state.currentEditable) {
                state.currentEditable.name = action.payload;
            }
        },

        setTemplateDefault(state, action: PayloadAction<boolean>) {
            if (state.currentEditable) {
                state.currentEditable.isDefault = action.payload;
                state.data = state.data.map(d => {
                    if (d.id === state.currentEditable?.id) {
                        d.isDefault = action.payload;
                    }
                    d.isDefault = false;
                    return d;
                })
            }
        },

        // editing category group

        addNewGroup(state) {
            if (state.currentEditable) {
                state.currentEditable.categories.push({
                    ...defaultCategory,
                    graphs: [ {
                        ...defaultGraphs as any,
                        id: `chart-new-${nanoid(16)}`
                    }],
                    id: `category-new-${nanoid(16)}`,
                })
            }
        },

        removeTemplateGroup(state, action: PayloadAction<number>) {
            if (state.currentEditable) {
                const index = action.payload;
                state.currentEditable.categories.splice(index, 1);
            }
        },

        reorderCategory(state, action: PayloadAction<MetricGraph["categories"]>) {
            const categories = action.payload;
            if (state.currentEditable) {
                state.currentEditable.categories = categories as any;
            }
        },


        setGroupName(state, action: PayloadAction<{ name: string, index: number }>) {
            if (state.currentEditable) {
                const { name, index } = action.payload;
                state.currentEditable.categories[index].name = name;
            }
        },

        setGroupIcon(state, action: PayloadAction<{ icon: string, index: number }>) {
            if (state.currentEditable) {
                const { icon, index } = action.payload;
                state.currentEditable.categories[index].icon = icon;
            }
        },

        setGroupColumnCount(state, action: PayloadAction<{ column: 1 | 2 | 3, index: number }>) {
            if (state.currentEditable) {
                const { column, index } = action.payload;
                state.currentEditable.categories[index].columnCount = column;
            }
        },

        // editing chart config

        addNewChart(state, action: PayloadAction<number>) {
            if (state.currentEditable) {
                const index = action.payload;
                state.currentEditable.categories[index].graphs.push({
                    ...defaultGraphs as any,
                    id: `chart-new-${nanoid(16)}`
                })
            }
        },

        removeChart(state, action: PayloadAction<{ groupIndex: number, chartIndex: number }>) {
            if (state.currentEditable) {
                const { groupIndex, chartIndex } = action.payload;
                state.currentEditable.categories[groupIndex].graphs.splice(chartIndex, 1);
            }
        },

        reorderGraphs(state, action: PayloadAction<{ newList: CategoryGraph[], groupIndex: number }>) {
            const { newList, groupIndex } = action.payload;
            if (state.currentEditable) {
                state.currentEditable.categories[groupIndex].graphs = newList as any;
            }
        },

        setChartName(state, action: PayloadAction<{ name: string, groupIndex: number, chartIndex: number }>) {
            if (state.currentEditable) {
                const { name, groupIndex, chartIndex } = action.payload;
                state.currentEditable.categories[groupIndex].graphs[chartIndex].name = name;
            }
        },

        setChartType(state, action: PayloadAction<{ value: string, groupIndex: number, chartIndex: number }>) {
            if (state.currentEditable) {
                const { value, groupIndex, chartIndex } = action.payload;
                state.currentEditable.categories[groupIndex].graphs[chartIndex].graphType = value;
            }
        },

        // editing metrics config

        addNewMetric(state, action: PayloadAction<{ groupIndex: number, chartIndex: number }>) {
            if (state.currentEditable) {
                const { groupIndex, chartIndex } = action.payload;
                state.currentEditable.categories[groupIndex].graphs[chartIndex].metrics.push({
                    ...defaultMetrics,
                    id: `metric-new-${nanoid(16)}`
                })
            }
        },

        removeMetric(state, action: PayloadAction<{ groupIndex: number, chartIndex: number, index: number }>) {
            if (state.currentEditable) {
                const { groupIndex, chartIndex, index } = action.payload;
                state.currentEditable.categories[groupIndex].graphs[chartIndex].metrics.splice(index, 1);
            }
        },

        reorderMetrics(state, action: PayloadAction<{ newList: MetricGraph["categories"][0]["graphs"][0]["metrics"], groupIndex: number, chartIndex: number }>) {
            const { newList, groupIndex, chartIndex } = action.payload;
            if (state.currentEditable) {
                state.currentEditable.categories[groupIndex].graphs[chartIndex].metrics = newList;
            }
        },

        setMetricName(state, action: PayloadAction<{ value: string, groupIndex: number, chartIndex: number, metricIndex: number }>) {
            if (state.currentEditable) {
                const { value, groupIndex, chartIndex, metricIndex } = action.payload;
                state.currentEditable.categories[groupIndex].graphs[chartIndex].metrics[metricIndex].name = value;
            }
        },

        setMetricUnit(state, action: PayloadAction<{ value: string, groupIndex: number, chartIndex: number, metricIndex: number }>) {
            if (state.currentEditable) {
                const { value, groupIndex, chartIndex, metricIndex } = action.payload;
                state.currentEditable.categories[groupIndex].graphs[chartIndex].metrics[metricIndex].unit = value;
            }
        },

        setMetricAggregationType(state, action: PayloadAction<{ value: string | AggregationType, groupIndex: number, chartIndex: number, metricIndex: number }>) {
            if (state.currentEditable) {
                const { value, groupIndex, chartIndex, metricIndex } = action.payload;
                state.currentEditable.categories[groupIndex].graphs[chartIndex].metrics[metricIndex].aggregationType = value;
            }
        },

        setMetricTarget1(state, action: PayloadAction<{ value: number | undefined, groupIndex: number, chartIndex: number, metricIndex: number }>) {
            if (state.currentEditable) {
                const { value, groupIndex, chartIndex, metricIndex } = action.payload;
                state.currentEditable.categories[groupIndex].graphs[chartIndex].metrics[metricIndex].target1 = value;
            }
        },
        setMetricTarget2(state, action: PayloadAction<{ value: number | undefined, groupIndex: number, chartIndex: number, metricIndex: number }>) {
            if (state.currentEditable) {
                const { value, groupIndex, chartIndex, metricIndex } = action.payload;
                state.currentEditable.categories[groupIndex].graphs[chartIndex].metrics[metricIndex].target2 = value;
            }
        },
        setMetricTargetOperator(state, action: PayloadAction<{ value: string | undefined, groupIndex: number, chartIndex: number, metricIndex: number }>) {
            if (state.currentEditable) {
                const { value, groupIndex, chartIndex, metricIndex } = action.payload;
                state.currentEditable.categories[groupIndex].graphs[chartIndex].metrics[metricIndex].targetOperator = value;
            }
        },

        setMetricFormula(state, action: PayloadAction<{ value: string, groupIndex: number, chartIndex: number, metricIndex: number }>) {
            if (state.currentEditable) {
                const { value, groupIndex, chartIndex, metricIndex } = action.payload;
                state.currentEditable.categories[groupIndex].graphs[chartIndex].metrics[metricIndex].key = value;
            }
        },

    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchMetricsTemplate.fulfilled, (state, action) => {
                state.data = action.payload as any;
            })
            .addCase(fetchMetricsTemplate.rejected, (state, action) => {
                state.data = []
                console.log(action.error);
            })
    }
})

export const kpiTemplateActions = kpiTemplateSlice.actions;
export default kpiTemplateSlice.reducer;

export const kpiTemplateData = (state: RootState) => state.kpiTemplate.data;
export const kpiTemplateOpen = (state: RootState) => state.kpiTemplate.open;
export const kpiTemplateTechnology = (state: RootState) => state.kpiTemplate.technology;
export const kpiTemplateLoading = (state: RootState) => state.kpiTemplate.templateLoading;

export const kpiTemplateTestGraphOptions = (state: RootState) => state.kpiTemplate.testGraphOptions;
export const kpiTemplateCurrentEditable = (state: RootState) => state.kpiTemplate.currentEditable;
export const kpiTemplateVendor = (state: RootState) => state.kpiTemplate.vendor;


function ToCamelCase(string: string) {
    const str = string.toLowerCase();
    return str.charAt(0).toUpperCase() + str.slice(1);
}