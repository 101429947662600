/** 
 Copyright Highway9 Networks Inc. 
 */ 
import { OperatorQuery, OperatorRadioQuery, OperatorRadios, bandConfigData } from "../types/OperatorQuery";
import { RequestOptions } from "../types/requestOptions";
import { authenticationService } from "./authentication-service";

export const operatorService = {
  getAllOperators,
  getOperatorsByEdge,
  getIPSecParams,
  getOperatorRadios,
  submitOperatorRadio,
  deleteOperatorRadio,
};
async function getIPSecParams() {
  try {
    const requestOptions: RequestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: "Bearer " + authenticationService.currentUserValue.token,
      },
    };
    const response = await fetch(`/api/v1/orch/operator/ipsecParams`, requestOptions);
    const text = await response.text();
    const data = text && JSON.parse(text).data;
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}
async function getAllOperators() {
  try {
    const requestOptions: RequestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: "Bearer " + authenticationService.currentUserValue.token,
      },
    };
    const response = await fetch(`/api/v1/orch/operator`, requestOptions);
    const text = await response.text();
    const data = text && JSON.parse(text).data;
    return data as OperatorQuery[];
  } catch (error) {
    console.log(error);
    throw error;
  }
}
async function getOperatorsByEdge(edgeId: string): Promise<OperatorQuery[]> {
  const payload = { edgeId };
  const requestOptions: RequestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
      Authorization: "Bearer " + authenticationService.currentUserValue.token,
    },
    body: JSON.stringify(payload),
  };

  try {
    const response = await fetch(`/api/v1/orch/operators/query`, requestOptions);
    const text = await response.text();
    const data = text && JSON.parse(text).data;
    return data as OperatorQuery[];
  } catch (error) {
    console.log(error);
    throw error;
  }
}
async function getOperatorRadios(payload: OperatorRadios) {
  try {
    const requestOptions: RequestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: "Bearer " + authenticationService.currentUserValue.token,
      },
      body: JSON.stringify(payload),
    };
    const response = await fetch(`/api/v1/orch/operatorRadios/query`, requestOptions);
    const text = await response.text();
    const data = text && JSON.parse(text).data;
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}
async function submitOperatorRadio(payload: OperatorRadioQuery | bandConfigData, method: string) {
  try {
    const {id,...requestBody} = payload
    const _id = method === "PUT" ? `/${payload.id}` : "";
  
    const requestOptions: RequestOptions = {
      method,
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: "Bearer " + authenticationService.currentUserValue.token,
      },
      body: JSON.stringify(requestBody),
    };
    const response = await fetch(`/api/v1/orch/operatorRadio${_id}`, requestOptions);
    
    const text = await response.text();
    const data = text && JSON.parse(text).data;
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}
async function deleteOperatorRadio(id: string | undefined) {
  try {
    const requestOptions: RequestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: "Bearer " + authenticationService.currentUserValue.token,
      },
    };
    const response = await fetch(`/api/v1/orch/operatorRadio/${id}`, requestOptions);

    return response;
  } catch (error) {
    console.log(error);
    throw error;
  }
}
