/**
 * Copyright 2023-2024 Highway9 Networks Inc.
 */

import { IT_DASHBOARD } from "~/constants/routes";
import { userService } from "~/services";
import { store } from "~/store";
import { setUser, setUserPermissions, setUserPermissionsLoading } from "~/store/context/authSlice";
import { dashboardActions } from "~/store/context/dashboardSlice";
import { fetchSystemSettings } from "~/store/context/settingsSlice";
import { utilityActions } from "~/store/context/utilitySlice";
import evt from "~/store/event";

function onAppStart() {
  console.log("App started");

  store.dispatch(fetchSystemSettings());
  
  // Get query params
  const params = new URLSearchParams(window.location.search);
  const startTime = params.get("startTime");
  const endTime = params.get("endTime");
  const interval = params.get("interval");

  if (window.location?.pathname === "/") {
    if (startTime) {
      store.dispatch(dashboardActions.setStartTime(Number(startTime)));
    }
    if (endTime) {
      store.dispatch(dashboardActions.setEndTime(Number(endTime)));
    }
    if (interval) {
      store.dispatch(dashboardActions.setTimeInterval({ interval }));
    }
  }else {
    if (startTime) {
      store.dispatch(utilityActions.setStartTime(Number(startTime)));
    }
    if (endTime) {
      store.dispatch(utilityActions.setEndTime(Number(endTime)));
    }
    if (interval) {
      store.dispatch(utilityActions.setTimeInterval({ interval }));
    }
  }

  const aggregationPeriod = params.get("aggregationPeriod");
  if (aggregationPeriod) {
    store.dispatch(utilityActions.setAggregationPeriod(aggregationPeriod));
  }

  const id = params.get("id");
  if (id) {
    store.dispatch(utilityActions.setSelectedID(id));
  }

  // fetch on startup
  if(location?.pathname !== IT_DASHBOARD) {
    evt.emit("refreshRadios"); // this refresh itself as it is bound to observe by radio simulator for the first time.
    evt.emit("refreshEdges");
    evt.emit("refreshEdgePools");
    evt.emit("refreshSites");
  }
  evt.emit("refreshServiceController");
  evt.emit("refreshInsights", "ACTIVE");

  // update timezone
  updateTimezone();

  // Update user permissions
  updateUserPermissions();
}

export default onAppStart;

async function updateTimezone() {
  const userInfo = await userService.getUserInfo();
  // sync userInfo to store
  if (!userInfo) {
    return;
  }
  store.dispatch(setUser(userInfo));

  if (userInfo.timezone) {
    store.dispatch(utilityActions.setTimeZone(userInfo.timezone));
    return;
  }
}

async function updateUserPermissions(){
  store.dispatch(setUserPermissionsLoading(true));
  try{
    const userPermissions = await userService.getUserPermissions();
    if(userPermissions){
      store.dispatch(setUserPermissions(userPermissions));
    }
  }catch(error){
    console.log("Could not fetch user permissions ",error);
  }finally{
    store.dispatch(setUserPermissionsLoading(false));
  }
}
