/** 
 Copyright Highway9 Networks Inc. 
 */ 
import EventMap from "./eventmap.json";
export const eventMap: Record<
  string,
  {
    Color: string;
    Code: string;
    Filter?: string;
  }
> = EventMap;
