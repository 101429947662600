/** 
 Copyright Highway9 Networks Inc. 
 */ 
import { Badge } from "@mui/material";
import { styled } from "@mui/styles";

export const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: 19,
    top: 14,
    border: `2px solid #026950`,
    padding: "0 4px",
    backgroundColor: "rgb(232,238,237)",
    height: 18,
    width: 10,
    color: "#026950",
  },
}));

export const BaseBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: 8,
    top: 21,
    border: `2px solid #026950`,
    padding: "0px 4px 0px 4px",
    backgroundColor: "rgb(232,238,237)",
    height: 12,
    width: 12,
    color: "#026950",
    fontSize: 6,
  },
}));
