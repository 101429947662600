/**
 * Copyright 2023-2024 Highway9 Networks Inc.
 */
import React, { useEffect } from "react";
import SideDrawerNew, { DRAWER_WIDTH } from "./SideDrawerNew";
import { authenticationService } from "~/services";
import { text } from "~/components/TextResources";
import { TopBarNew } from "./TopBar";
import { useAppSelector } from "~/store/hooks";
import { selectCurrentServiceController } from "~/store/context/dashboardSlice";
import { Grid } from "@mui/material";
import Assistant, { CHATBOT_WIDTH } from "./assistant/Assistant";
import { settingsAirgapEnabled, settingsAssistantEnabled } from "~/store/context/settingsSlice";

type Props = {
  children: React.ReactNode;
};

const styles = {
  wrapper: {
    background: '#226A58',
    height: "100vh",
    flexWrap: "nowrap",
    width: "100%"
    // paddingTop: '16px'
  },
  main: {
    flexGrow: 1,
    maxWidth: `100%`,
    overflow: "auto",
    height: "100%",
    background: '#F7F7F6',
    padding: '16px'
  },
  container: {
    overflow: "auto",
    flexGrow: 1,
    flexDirection: "column",
    flexWrap: "nowrap",
    maxWidth: `calc(100% - ${DRAWER_WIDTH}px)`,
    paddingTop: '16px',
  },
};

const LayoutNew = (props: Props) => {
  const serviceInstanceDisplayName = useAppSelector(selectCurrentServiceController)?.displayName!;
  const serviceInstanceName = authenticationService?.currentUserValue?.serviceInstanceName ?? "";

  useEffect(() => {
    const title = `${serviceInstanceDisplayName ? serviceInstanceDisplayName : serviceInstanceName} - ${text.title}`
    document.title = import.meta.env.DEV ? `DEV - ${title}` : title;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceInstanceDisplayName]);

  const botDocked = useAppSelector((state) => state.utility.chatBot.maximize && state.utility.chatBot.open);

  const isAssistantEnabled = useAppSelector(settingsAssistantEnabled);  

  const AirGapEnabled = useAppSelector(settingsAirgapEnabled);

  const uniqueIdForBody = "bodyToScrollInLayout"
  return (
    <>
      <Grid container sx={styles.wrapper}>
        <SideDrawerNew />
        <Grid container item sx={styles.container}>
          <TopBarNew title={serviceInstanceDisplayName} subtitle={text.topBarSubtitle} bodyId={uniqueIdForBody} />
          <Grid item component="main" id={uniqueIdForBody} sx={{
            ...styles.main,
            maxWidth: botDocked ? `calc(100% - ${CHATBOT_WIDTH})` : `100%`
          }}>
            {props.children}
          </Grid>
        </Grid>
        {(!AirGapEnabled && isAssistantEnabled) && <Assistant />}
      </Grid>
    </>
  );
};

export default LayoutNew;
