/** 
 Copyright Highway9 Networks Inc. 
 */ 
import React, { useEffect, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Grid, Alert } from "@mui/material";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    //  paddingTop : theme.spacing(1),
    //   position:"absolute",
    //   zIndex :1,
    //   background:"white",
    paddingBottom: theme.spacing(1),
    "& > * + *": {
      marginTop: 2,
    },
  },
  orderWizard: {
    width: "100%",
    marginTop: 8,
  },
}));
const ErrorsComponent = ({ errorsData, warningsData, closeAlert, closeAll }) => {
  const classes = useStyles();
  const [errors, setErrors] = useState(errorsData);
  const [warnings, setWarnings] = useState(warningsData);
  useEffect(() => {
    setErrors(errorsData);
    setWarnings(warningsData);
  }, [errorsData, warningsData]);

  return (
    <Grid className={classes.root}>
      {errors
        ? errors.map((item, index) => (
            <Alert
              key={index}
              severity="error"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    closeAlert(index, "error");
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {item.message}
            </Alert>
          ))
        : null}
      {warnings
        ? warnings.map((item, index) => (
            <Alert
              key={index}
              severity="warning"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    closeAlert(index, "warning");
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {item.message}
            </Alert>
          ))
        : null}
      {((errors && errors?.length + warnings?.length > 1) || (warnings && errors?.length + warnings?.length > 1)) && (
        <Box display="flex" justifyContent="right">
          <Button color="primary" onClick={closeAll}>
            Close All
          </Button>
        </Box>
      )}
    </Grid>
  );
};
ErrorsComponent.defaultProps = {
  errorsData: [],
  warningsData: [],
};
export default ErrorsComponent;
