/** 
 Copyright Highway9 Networks Inc. 
 */ 
import { Device } from "~/types/device";
import { DeviceInfo } from "~/types/deviceInfo";
import APIService, { MetricsQuery } from "./APIServices";
import { authenticationService } from "./authentication-service";
import { DeviceGroup } from "~/types/deviceGroup";
import { apiFetch } from "~/sw";

const deviceAPI = new APIService<Device>("devices");
const deviceInfoAPI = new APIService<DeviceInfo>("deviceInfo");
const deviceGroupAPI = new APIService<DeviceGroup>("deviceGroups");

export const subscriberService = {
  getSubscribers: async () => await apiFetch("fetchDevices", authenticationService.currentUserValue.token),
  getSubscribersDeviceInfo: deviceInfoAPI.getAll,
  createSubscriber: deviceAPI.create,
  updateSubscriber: deviceAPI.update,
  deleteDevice: deviceAPI.delete,
  updateSubscribersBulk: (devices: Device[]) => deviceAPI.put('bulk', devices),
  getMetrics: async (query: MetricsQuery) => await apiFetch("fetchDeviceMetrics", query, authenticationService.currentUserValue.token),
  importSubscribers,
  getDeviceGroups: deviceGroupAPI.getAll,
  addDeviceGroup: deviceGroupAPI.create,
  updateDeviceGroup: deviceGroupAPI.update,
  deleteDeviceGroup: deviceGroupAPI.delete,
  getTimelineData,
  getPingmonData
};

function importSubscribers(file: File) {
  const formData = new FormData();
  formData.append("upfile", file);

  const requestOptions = {
    method: "POST",
    headers: { accept: "*/*", Authorization: "Bearer " + authenticationService.currentUserValue.token },
    body: formData,
  };

  return fetch(`/api/v1/orch/devices/operations/import`, requestOptions);
}

async function getTimelineData(query : MetricsQuery, radioMap : Record<string, string>){
  return await apiFetch("fetchTimelineGraphData", query, radioMap, authenticationService.currentUserValue.token) 
}

async function getPingmonData(query : MetricsQuery, fullsync : boolean, oldRawData : any[]){
  return await apiFetch("fetchPingmonData", query, fullsync, oldRawData,  authenticationService.currentUserValue.token) 
}