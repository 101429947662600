/** 
 Copyright Highway9 Networks Inc. 
 */ 
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Insight } from "~/types/Insight"
import insightService from "~/services/insight-service";

type initState = {
  data: Insight[];
  loading: boolean;
  sourceFilter: string;
}

export const initialState: initState = {
  data: [],
  loading: true,
  sourceFilter: "",
};

export const fetchInsights = createAsyncThunk( "itDashboard/fetchInsights", async (filter : Partial<Insight>) => {
  const response = await insightService.getInsights(filter) 
  return response;
});
const insightSlice = createSlice({
  name: "insight",
  initialState,
  reducers: {
    setInsights: (state, action) => {
      state.data = action.payload;
      state.loading = false;
    },
    setSourceFilter: (state, action) => {
      state.sourceFilter = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchInsights.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchInsights.fulfilled, (state, action) => {
      state.data = action.payload;
      state.loading = false;
    });
    builder.addCase(fetchInsights.rejected, (state) => {
      state.loading = false;
    });
  },
});


export const insightAction = insightSlice.actions;

export default insightSlice.reducer;

