/** 
 Copyright Highway9 Networks Inc. 
 */ 
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { diagnosticsUeService, simulatorServices } from "~/services";
import { Simulator } from "~/types/simulator";
import { RootState, store } from "..";
import Radio from "~/types/radio";
import { UE_Simulator } from "~/types/ueSimulator";

import { observeStoreChange } from "../utils";

type SimulatorState = {
  open: boolean;
  deleteOpen: boolean;
  loading: boolean;
  data: Simulator[];

  radioSimulators: Radio[];
  current: Simulator | null;
  openRadioSimulator: boolean;
  openDeleteRadioSimulator: boolean;
  currentRadioSimulator: Radio | null;

  ueSimLoading: boolean;
  ueSimulators: UE_Simulator[];
};

const initialState: SimulatorState = {
  open: false,
  deleteOpen: false,
  loading: true,
  data: [],

  current: null,
  currentRadioSimulator: null,
  openRadioSimulator: false,
  openDeleteRadioSimulator: false,
  radioSimulators: [],

  ueSimLoading: true,
  ueSimulators: [],
};

export const fetchSimulator = createAsyncThunk("simulator/fetchSimulator", async () => {
  const data = await simulatorServices.getSimulators();
  return data;
});

export const fetchRadioSimulator = createAsyncThunk("simulator/fetchRadioSimulator", async (_, thunk) => {
  const state = thunk.getState() as RootState;
  const radios = state.radio.data;
  // if (radios.length === 0) {
  //   await thunk.dispatch(fetchRadios());
  //   const _state = thunk.getState() as RootState;
  //   radios = _state.radio.data;
  // }
  const simulatedRadios = radios.filter((radio) => radio.isSimulatedRadio);
  return simulatedRadios;
});

export const fetchUESimulators = createAsyncThunk("simulator/fetchUESimulators", async () => {
  const data = await diagnosticsUeService.getDiagnosticsUe();
  return data;
});

setTimeout(() => {
  // fetch the radioSimulator whenever radios are fetched
  observeStoreChange(
    (state) => state.radio.data,
    (radios: Radio[]) => {
      if (radios.length) store.dispatch(fetchRadioSimulator());
    }
  );
});

const simulatorSlice = createSlice({
  name: "simulator",
  initialState,
  reducers: {
    setOpen(state, action: PayloadAction<boolean>) {
      state.open = action.payload;
    },
    setDeleteOpen(state, action: PayloadAction<boolean>) {
      state.deleteOpen = action.payload;
    },
    setValue(state, action: PayloadAction<Simulator | null>) {
      state.current = action.payload;
    },
    setRadioSimulator(state, action: PayloadAction<Radio | null>) {
      state.currentRadioSimulator = action.payload;
    },
    setOpenRadioSimulator(state, action: PayloadAction<boolean>) {
      state.openRadioSimulator = action.payload;
    },
    setOpenDeleteRadioSimulator(state, action: PayloadAction<boolean>) {
      state.openDeleteRadioSimulator = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSimulator.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
      })
      .addCase(fetchRadioSimulator.fulfilled, (state, action) => {
        state.radioSimulators = action.payload;
        state.loading = false;
      })
      .addCase(fetchUESimulators.fulfilled, (state, action) => {
        state.ueSimulators = action.payload;
        state.loading = false;
        state.ueSimLoading = false;
      });
  },
});

export const simulatorAction = simulatorSlice.actions;

export default simulatorSlice.reducer;

export const simulatorState = (state: RootState) => state.simulator.current;
export const simulatorOpen = (state: RootState) => state.simulator.open;
export const simulatorLoading = (state: RootState) => state.simulator.loading;
export const simulatorData = (state: RootState) => state.simulator.data;

export const radioSimulatorState = (state: RootState) => state.simulator.currentRadioSimulator;
export const radioSimulatorOpen = (state: RootState) => state.simulator.openRadioSimulator;
export const radioSimulatorDeleteOpen = (state: RootState) => state.simulator.openDeleteRadioSimulator;

export const ueSimulatorData = (state: RootState) => state.simulator.ueSimulators;
