/**
 * Copyright Highway9 Networks Inc.
 */
import { AuditLogResponse } from "~/types/auditLog";
import { authenticationService } from ".";
import APIService from "./APIServices";
import { Permission, UserInfo } from "~/types/user";

const userAPI = new APIService<UserInfo>("users");

export const userService = {
  getAuditLogs,
  updateUserProfile,
  getUserInfoByID,
  updateUserInfo: userAPI.update,
  getUserInfo,
  getUserPermissions,
  get currentUserInfo() {
    return getUserInfoLocalStorage();
  },
  createUser: userAPI.create,
  getAllUser : userAPI.getAll,
  deleteUser: userAPI.delete,
  updateUser: userAPI.update,
  getUserResetPasswordLink,
};

async function getAuditLogs(filters: any) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
      Authorization: "Bearer " + authenticationService.currentUserValue?.token,
    },
    body: JSON.stringify(filters),
  };
  const logs = await fetch("/api/v1/orch/auditLogs/query", requestOptions).then((res) => res.json());
  // console.log(logs)
  return logs as AuditLogResponse;
}

async function updateUserProfile(data: any) {
  const profile = await userAPI.post("profile", data);
  return profile as UserInfo;
}
async function getUserInfoByID(id: string) {
  const profile = await userAPI.get(id);
  return profile;
}

async function getUserInfo() {
  const currentUser = authenticationService.currentUserValue;
  if (!currentUser) return null;
  const userInfo = await userService.getUserInfoByID(currentUser.userId);
  // console.log(userInfo);
  localStorage.setItem("userInfo", JSON.stringify(userInfo));

  return userInfo;
}

function getUserInfoLocalStorage() {
  if (typeof window !== "undefined") {
    const state = localStorage.getItem("userInfo");
    if (state) {
      return JSON.parse(state) as UserInfo;
    } else {
      return null;
    }
  }
  return null;
}

async function getUserPermissions() {
  const currentUser = authenticationService.currentUserValue;
  if (!currentUser) return null;
  const permissions = await userAPI.get(`${currentUser.userId}/permissions`) as unknown as Permission[];
  return permissions;
}

async function getUserResetPasswordLink(email: string) {
  const operation = {
      "name": "user-forgot-password",
      "type": "operation",
      "operationType": "FORGOT_PASSWORD",
      "objectType": "user",
      "input": [
          {
              "key": "userName",
              "value": email
          }
      ]
  }

  const requestOptions = {
      method: "POST",
      headers: {
          "Content-Type": "application/json",
          accept: "application/json",
          Authorization: "Bearer " + authenticationService.currentUserValue.token,
      },
      body: JSON.stringify(operation),
  };

  const response = await fetch('/api/v1/orch/operations/forgotPassword', requestOptions).then(res => res.json());

  return response as {
      input : null,
      output : {
          key : string,
          value : string
      }[],
   };
}