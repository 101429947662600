/** 
 Copyright Highway9 Networks Inc. 
 */ 
import { proxy, releaseProxy } from "comlink";

export const workerInstance = new ComlinkWorker<typeof import("./workers")>(
    new URL("./workers", import.meta.url), { type: "module" });

/**
 * The `apiWrapper` function in TypeScript is an asynchronous function that wraps API calls using a web
 * worker and Comlink for improved performance and error handling.
 * @param cb - The `cb` parameter in the `apiWrapper` function is a callback function that is expected
 * to return a value of type `T`. This callback function is passed to the `apiWorker.fetchData` method
 * to fetch data using a web worker.
 * @returns The `apiWrapper` function is returning the result of fetching data from the API worker
 * after executing the provided callback function `cb`.
 */
export async function apiWrapper<T>(cb: () => T) {
    // Create the worker
    const apiWorker = new ComlinkWorker<typeof import("./apiWorker")>(
        new URL("./apiWorker", import.meta.url), { type: "module" });


    try {
        // Fetch the data
        const result = await apiWorker.fetchData(proxy(cb)) as T;
        // Return the result
        return result;
    }
    catch (error) {
        // Handle the error
        console.error(error);
        throw error;
    }
    finally {
        // Terminate the worker
        apiWorker.terminate();

        // Release the Comlink proxy
        apiWorker[releaseProxy]();
    }
}

type FetchWorker = typeof import("./fetchWorker");
type FetchWorkerReturnTypes = {
    [K in keyof FetchWorker]: FetchWorker[K] extends (...args: any[]) => Promise<infer R> ? R : never;
  };

/**
 * This TypeScript function uses Comlink to create a worker instance for fetching data and terminates
 * the worker after 30 seconds of inactivity.
 * @param key - The `key` parameter in the `apiFetch` function is of type `keyof FetchWorker`, which
 * means it can only be one of the keys defined in the `FetchWorker` interface. This parameter is used
 * to determine which method of the `apiWorker` to call based on the key
 * @param {FetchWorkerArgs} args - The `args` parameter in the `apiFetch` function represents the
 * arguments that will be passed to the worker function specified by the `key`. These arguments are
 * spread into an array and then passed to the worker function when invoking it.
 * @returns The `apiFetch` function returns the result of calling a specific method on a worker
 * instance (`apiWorker`) based on the provided key and arguments. The result of the worker method call
 * is returned from the `apiFetch` function.
 */
export const apiFetch = async  <K extends keyof FetchWorker>(key: K, ...args: Parameters<FetchWorker[K]>)=> {
    // Create the worker instance using the ComlinkWorker class
    const apiWorker = new ComlinkWorker<FetchWorker>(
        new URL("./fetchWorker", import.meta.url), { type: "module" });
    try {
        // @ts-expect-error - The args are passed to the worker function as an array.
        const result = await apiWorker[key](...args);
        return result as FetchWorkerReturnTypes[K];
    }
    catch (error) {
        console.error(error);
        throw error;
    }
    finally {
        // Terminate the worker after 10 seconds of inactivity to prevent memory leaks and other issues.
        setTimeout(() => {
            apiWorker.terminate();
            apiWorker[releaseProxy]();
        }, 1000 * 10);
    }
};