/** 
 Copyright Highway9 Networks Inc. 
 */ 
import { CloudLocation } from "~/types/CloudLocation";

import APIService from "./APIServices";

const locationAPI = new APIService<CloudLocation>('location')

export const locationService = {
  getLocation : locationAPI.getAll
};

